@media (max-width: 1299px) {
    .home-page .home-page-sidebar {
        width: 25%;
    }

    .home-page .home-page-content {
        width: 75%;
    }
}

@media (max-width: 1199px) {
    .home-page .home-page-sidebar {
        width: 80%;
    }

    .home-page .home-page-content {
        width: 100%;
        order: -1;
    }
}

@media (max-width: 991px) {

    .home-boxs {
        justify-content: center !important;
    }

    .header-form>div:first-child,
    header .header-name {
        display: none !important;
    }

    .settings {
        justify-content: center !important;
    }

    .first-box,
    .second-box {
        width: 48% !important;
    }


    .first-box {
        margin-left: 2%;
    }

    .thired-box {
        justify-content: center;
        align-content: center;
        align-self: center;
        width: 80% !important;
    }
}

@media (max-width: 768px) {
    .home-page .home-page-sidebar {
        padding: 1rem !important;
    }

    .header-form {
        margin-bottom: 10px;
    }

    .first-box,
    .second-box,
    .thired-box,
    .home-page .home-page-sidebar {
        margin: 0 !important;
        margin-bottom: 20px !important;
        width: 100% !important;
    }

}