* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none !important;
}

:root {
  --page-color: #eaeef6;
  --white: #ffffff;
  --off-white: #c6cad2;
  --green: #44bc85;
  --blue: #0a9ff8;
  --vivid-cerulean: #0aa0f8;
  --blue-bell: #7783ed;
  --periwinkle-grey: #d2d7fe;
  --yellow: #f1b66e;
  --transition: 0.5s;
  --radius: 8px;
  --circle-radius: 50%;
}

/* 
  -- scrollbar
*/

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: var(--periwinkle-grey) !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--blue-bell) !important;
}

body {
  font-family: 'Noto Sans Arabic', sans-serif;
  background-color: var(--page-color) !important;
  overflow-x: hidden;
}

input {
  outline: none;
  border: none;
}

img {
  cursor: pointer;
}

a {
  transition: var(--transition);
}

/* 
  -- gloabl classes
*/
.close-btn {
  font-size: 13px;
  color: #b7becc;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.hidden {
  transform: scale(0);
}

.min-button {
  border-radius: var(--radius);
  font-size: 13px;
  font-weight: bold;
  color: white;
  background-color: var(--blue-bell);
  border: none;
  outline: none;
  text-align: center;
  padding: 7px 10px;
  width: 100%;
}

.min-title {
  border-radius: calc(var(--radius) + 5px);
  padding: 2px 10px;
  font-size: 11px;
  background-color: #cfecfd;
  color: var(--vivid-cerulean);
  font-weight: bold;
}

.scale {
  transform: scale(1) !important;
}

.img-sm {
  width: 25px;
  height: 25px;
}

.circle-element {
  border-radius: var(--circle-radius) !important;
}

.center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.d-flex-bteween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-bold {
  font-weight: 600 !important;
}

.f-bolder {
  font-weight: 900 !important;
}

.fs-7 {
  font-size: 14px !important;
}

.home-page .home-page-sidebar {
  width: 20%;
}

.home-page .home-page-content {
  width: 70%;
}

/* 
  -- home sidebar
*/

textarea.add-task-input {
  resize: none;
  width: 100%;
  height: 100px;
  border: 1px solid #c9cce2;
  box-shadow: 0 0 2px var(--blue);
  border-radius: var(--radius);
  outline: none;
}

.hint-message {
  position: absolute;
  width: 100%;
  right: 0;
  top: 0px;
  text-align: center;
  color: var(--blue);
  font-weight: bold;
  font-size: 12px;
  background-color: #e2f2fd;
  border-radius: var(--radius);
}

.delete {
  border-radius: var(--radius);
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  right: 18px;
  top: 10px;
  cursor: pointer;
  user-select: none;
  transition: var(--transition);
  transform: scale(0);
}

.delete.active {
  transform: scale(1);
}

.add-task-input::placeholder {
  color: #b7becc;
  font-size: 13px;
}

.fa-tag,
.fa-user-plus {
  color: var(--off-white);
}

.main-cricle {
  border: 1px dashed var(--off-white);
  width: 20px;
  height: 20px;
  border-radius: var(--circle-radius);
  color: var(--off-white);
  cursor: pointer;
  z-index: 222222;
}

.check-box {
  border: 2px solid var(--blue);
  box-shadow: 0 0 5px var(--vivid-cerulean);
  width: 20px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.check-box .checked {
  font-size: 11px;
  position: absolute;
  left: -2px;
  top: -2px;
  color: white;
  width: 20px;
  height: 20px;
  background-color: var(--blue);
  transition: var(--transition);
  transform: scale(0);
}

/* 
  -- Header
*/

.min-input {
  background-color: #eaeef6a6;
  padding: 3px 15px;
  border-radius: calc(var(--radius) + 10px);
}

#search-input {
  position: relative;
  padding-left: 35px !important;
}

#search-task {
  padding-left: 35px !important;
}

#search-input::placeholder {
  color: var(--off-white);
  font-weight: bold;
  font-size: 12px;
}

header .search-icone,
.search-task-icone {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 11px;
  color: var(--off-white);
  cursor: pointer;
}

.nav-link {
  transition: var(--transition);
}

.nav-link.active {
  color: #26b1ff !important;
}

.fa-bell::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  right: -4px;
  top: -3px;
}

/* 
  -- Assign Task card
*/

.task.active {
  border-left: 3px solid #73accd;
  background-color: #f0f5fd;
}

/* 
  --weekly progress circle
*/

.circular {
  height: 200px;
  width: 200px;
}

.circular .inner,
.circular .outer,
.circular .circle {
  position: absolute;
  z-index: 6;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2);
}

.circular .inner {
  top: 50%;
  left: 50%;
  height: 180px;
  width: 180px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

.circular .circle {
  z-index: 1;
  box-shadow: none;
}

.circular .numb {
  z-index: 10;
  font-size: 28px;
  font-weight: bolder;
  color: green;
  text-align: center;
  margin-top: 5px;
}

.circular .numb p {
  font-size: 11px;
}

.circular .bar {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  clip: rect(0px, 200px, 200px, 100px);
}

.circle .bar .progress {
  position: absolute;
  border-radius: 50%;
  clip: rect(0px, 100px, 200px, 0px);
}

.circle .bar .progress,
.dot span {
  background: var(--green);
}

.circle .left .progress {
  z-index: 1;
  animation: left 2s linear both;
}

.circle .right {
  z-index: 3;
  transform: rotate(180deg);
}

.circle .right .progress {
  animation: right 2s linear both;
}

.circle .dot {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 10px;
  margin-top: -5px;
  animation: dot 8s linear both;
  transform-origin: 0% 50%;
}

.circle .dot span {
  position: absolute;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes left {
  100% {
    transform: rotate(180deg);
  }
}

@keyframes right {
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes dot {
  0% {
    transform: rotate(-90deg);
  }

  50% {
    transform: rotate(90deg);
    z-index: 4;
  }

  100% {
    transform: rotate(270deg);
    z-index: 4;
  }
}



@keyframes rotate-icone {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 
  --loader
*/
.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  color: var(--blue-bell);
  left: -150px;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  12% {
    box-shadow: 100px 0 var(--blue-bell), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  25% {
    box-shadow: 110px 0 var(--blue-bell), 100px 0 var(--blue-bell), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  36% {
    box-shadow: 120px 0 var(--blue-bell), 110px 0 var(--blue-bell), 100px 0 var(--blue-bell), 0px 0 rgba(255, 255, 255, 0);
  }

  50% {
    box-shadow: 130px 0 var(--blue-bell), 120px 0 var(--blue-bell), 110px 0 var(--blue-bell), 100px 0 var(--blue-bell);
  }

  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--blue-bell), 120px 0 var(--blue-bell), 110px 0 var(--blue-bell);
  }

  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--blue-bell), 120px 0 var(--blue-bell);
  }

  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 var(--blue-bell);
  }

  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

/* 
  setting nav
*/

.nav-wrapper {
  width: 50px !important;
  padding: 5px !important;
  position: fixed;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-wrapper ul {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-wrapper .link-item a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}


/* 
  -- setting toggle check box
*/


.toggle-checkbox {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.toggle-switch {
  background-color: #ccc;
  width: 50px;
  height: 22px;
  border-radius: 16px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
}

.toggle-switch::before {
  font-family: var(--fa-style-family-classic);
  content: "\f00d";
  font-weight: 900;
  background-color: white;
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  transition: 0.3s;
}

.toggle-checkbox:checked+.toggle-switch {
  background-color: blue;
}

.toggle-checkbox:checked+.toggle-switch::before {
  content: "\f00c";
  left: 32px;
  color: blue;
}

/* 
  -- Page Loader
*/
.page-loader {
  width: 106px;
  height: 56px;
  display: block;
  margin: 30px auto;
  background-image: linear-gradient(var(--blue-bell) 50px, transparent 0), linear-gradient(var(--blue-bell) 50px, transparent 0), linear-gradient(var(--blue-bell) 50px, transparent 0), linear-gradient(var(--blue-bell) 50px, transparent 0), radial-gradient(circle 14px, var(--blue-bell) 100%, transparent 0);
  background-size: 48px 15px, 15px 35px, 15px 35px, 25px 15px, 28px 28px;
  background-position: 25px 5px, 58px 20px, 25px 17px, 2px 37px, 76px 0px;
  background-repeat: no-repeat;
  position: relative;
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.page-loader::after,
.page-loader::before {
  content: '';
  position: absolute;
  width: 56px;
  height: 56px;
  border: 6px solid var(--blue-bell);
  border-radius: 50%;
  left: -45px;
  top: -10px;
  background-repeat: no-repeat;
  background-image: linear-gradient(var(--blue-bell) 64px, transparent 0), linear-gradient(var(--blue-bell) 66px, transparent 0), radial-gradient(circle 4px, var(--blue-bell) 100%, transparent 0);
  background-size: 40px 1px, 1px 40px, 8px 8px;
  background-position: center center;
  box-sizing: border-box;
  animation: rotation 0.3s linear infinite;
}

.page-loader::before {
  left: 25px;
  top: 60px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}